/* Copyright (C) WambaTech, Inc. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { getApplicationApiName, getApplicationBaseName } from "configuration/PathConfiguration";
import { IRestResponse } from "models/rest/IRestResponse";
import { IRestResponseWithData } from "models/rest/IRestResponseWithData";
import { isObject } from "util";
import { notifyError } from "./MiscUtility";
import { isNullOrWhiteSpace } from "./StringUtility";
import { isPromise } from "./TypeGuards";

export async function fetchPostAsync<T>(url: string, data?: any, options?: any): Promise<T> {
    return await new Promise<T>((resolve, reject) => {
        fetch(
            addApiPathToUrl(url),
            {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                redirect: "error",
                referrer: "no-referrer",
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: data ? JSON.stringify(data) : undefined,
                ...options
            })
            .then(response => resolve(response.json()))
            .catch(error => reject(error));
    });
}

export async function fetchGetAsync<T>(url: string, data?: any, options?: any): Promise<T> {
    return await new Promise<T>((resolve, reject) => {
        fetch(
            addApiPathToUrl(processGetUrl(url, data)),
            {
                method: "GET",
                mode: "cors",
                cache: "no-cache",
                redirect: "error",
                referrer: "no-referrer",
                credentials: 'include',
                ...options
            })
            .then(response => resolve(response.json()))
            .catch(error => reject(error));
    });
}

export function addApiPathToUrl(url: string) {
    return addPathToUrl(
        getApplicationApiName(),
        url);
}

export function addBasePathToUrl(url: string) {
    return addPathToUrl(
        getApplicationBaseName(),
        url);
}

export function addPathToUrl(prefix: (string | undefined)[] | string | undefined, url: string) {
    let result = [
        ...(Array.isArray(prefix) ? prefix : [prefix || '']).map(item => (item || '').split('/').filter(r => !isNullOrWhiteSpace(r))).flat(),
        ...url.split('/'),
    ].filter(r => !isNullOrWhiteSpace(r)).join("/");

    if (result.startsWith("http:/")) {
        return result.replace("http:/", "http://");
    }
    else if (result.startsWith("https:/")) {
        return result.replace("https:/", "https://");
    }
    else {
        return "/" + result;
    }
}

function processGetUrl(url: string, data?: any) {
    if (data === undefined || !isObject(data)) {
        return url;
    }

    const parameters = objectToGetParameters(data);
    const index = url.indexOf("?");

    if (index === -1) {
        return `${url}?${parameters}`;
    }
    else if (index === url.length - 1) {
        return `${url}${parameters}`;
    }
    else {
        return `${url}&${parameters}`;
    }
}

export function objectToGetParameters(source: any, includeEmptyValues: boolean = false) {
    return (
        Object
            .keys(source)
            .filter( // do not include properties with no values
                k => ((source[k] && source[k].length > 0) || source[k] === false || source[k] === 0) || includeEmptyValues)
            .map(
                (key) => {
                    if (Array.isArray(source[key])) {
                        var arrayParts = [];
                        for (var i = 0; i < source[key].length; i++)
                            arrayParts.push(
                                encodeURIComponent(key + '[]') + '=' + encodeURIComponent(source[key][i]));
                        return arrayParts.join('&');
                    }
                    return encodeURIComponent(key) + '=' + encodeURIComponent(source[key]);
                })
            .join('&'));
}

export function simulateSuccessRestCallAsync<T>(resolve: (value?: IRestResponse | PromiseLike<IRestResponse>) => void, minDuration: number = 0, maxDuration: number = 250): void {
    setTimeout(
        () => {
            resolve({
                success: true,
                message: "OK"
            });
        },
        (Math.random() * (maxDuration - minDuration)) + minDuration);
}

export function simulateSuccessRestCallWithDataAsync<T>(resolve: (value?: IRestResponseWithData<T> | PromiseLike<IRestResponseWithData<T>>) => void, data: T, minDuration: number = 0, maxDuration: number = 250): void {
    setTimeout(
        () => {
            resolve({
                success: true,
                message: "OK",
                data
            });
        },
        (Math.random() * (maxDuration - minDuration)) + minDuration);
}


export async function handleRestResponseWithDataAsync<T>(response: IRestResponseWithData<T> | Promise<IRestResponseWithData<T>>, success?: (result: T) => void | Promise<void>, failure?: (data: IRestResponseWithData<T>) => void | Promise<void>) {
    if (isPromise(response)) {
        response = await response;
    }

    if (response && response.success && success) {
        if (isPromise(success)) {
            await success(response.data);
        }
        else {
            success(response.data);
        }
    }
    else if (!response || !response.success) {
        if (failure === undefined) {
            notifyError(new Error(response.message), "System REST Error");
        }
        else {
            if (isPromise(failure)) {
                await failure(response);
            }
            else {
                failure(response);
            }
        }
    }
}

export async function handleRestResponseAsync(response: IRestResponse | Promise<IRestResponse>, success?: () => void | Promise<void>, failure?: () => void | Promise<void>) {
    if (isPromise(response)) {
        response = await response;
    }

    if (response && response.success && success) {
        if (isPromise(success)) {
            await success();
        }
        else {
            success();
        }
    }
    else if (!response || !response.success) {
        if (failure === undefined) {
            notifyError(new Error(response.message), "System REST Error");
        }
        else {
            if (isPromise(failure)) {
                await failure();
            }
            else {
                failure();
            }
        }
    }
}