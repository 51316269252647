/* Copyright (C) WambaTech, Inc. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

export function isPromise(arg: any): arg is Promise<any> {
    return arg != null && isFunction(arg.then);
}

export function isFunction(arg: any): arg is (...args: any) => any {
    return arg != null && typeof arg === 'function';
}

export function isBoolean(arg: any): arg is boolean {
    return arg != null && typeof arg === 'boolean';
}

export function isObject(arg: any): arg is object {
    return arg != null && typeof arg === 'object';
}

export function isString(arg: any): arg is string {
    return arg != null && typeof arg === 'string';
}

export function isArray(arg: any): arg is Array<any> {
    return arg != null && Array.isArray(arg);
}

export function isDate(arg: any): arg is Date {
    return arg != null && Object.prototype.toString.call(arg) === "[object Date]";
}

export function isNumber(arg: any): arg is Number {
    return arg != null && typeof arg === "number";
}