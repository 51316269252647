/* Copyright (C) WambaTech, Inc. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { isString } from "./TypeGuards";

export function upperCaseLettersToWords(source: string) {
    return source.replace(/[A-Z]/g, " $&").trim();
}

export function removeAll(source: string, token: string) {
    return source.replace(
        new RegExp(`${token}`),
        "");
}

export function isNullOrWhiteSpace(source?: string): source is undefined {
    return source == null || (
        isString(source) &&
        source.match(/^ *$/) !== null);
}

export function randomString(length: number = 5): string {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < Math.random() * length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
}

export function isExternalLink(url: string) {
    return url &&
        (
            url.startsWith("http://") ||
            url.startsWith("https://")
        );
}

export function enumToString(enumeration: any, value: number, upperCaseWords: boolean = true) {
    for (const key of Object.keys(enumeration)) {
        if (enumeration[key] === value) {
            return upperCaseWords ? upperCaseLettersToWords(key) : key;
        }
    }

    return undefined;
}

export function stripHtml(html: string) {
    const tmpDiv = document.createElement("DIV");
    tmpDiv.innerHTML = html;
    return tmpDiv.textContent || tmpDiv.innerText || "";
}

export function stringToHtml(source?: string) {
    if (isNullOrWhiteSpace(source)) {
        return "";
    }

    return source.replace(/(?:\r\n|\r|\n)/g, '<br />');
}

export function getEmailValidationRegex() {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
}

export function getPhoneNumberValidationRegex() {
    return /^(\+0?1\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
}

export function formatCurrency(amount: any, decimalPlaces: boolean = false, local: string = 'en-US', currency: string = "USD"): string {
    let modifiedAmount = amount;

    if (typeof amount !== "number") {
        modifiedAmount = parseFloat(amount);

        if (isNaN(modifiedAmount)) {
            return amount;
        }
    }

    return (
        modifiedAmount.toLocaleString(local, {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: decimalPlaces ? 2 : 0,
            maximumFractionDigits: decimalPlaces ? 2 : 0
        }));
}

export function formatPercentage(amount: any, decimalPlaces: boolean = false, local: string = 'en-US', currency: string = "USD"): string {
    let modifiedAmount = amount;

    if (typeof amount !== "number") {
        modifiedAmount = parseFloat(amount);

        if (isNaN(modifiedAmount)) {
            return amount;
        }
    }

    return (
        modifiedAmount.toLocaleString(local, {
            style: 'percent',
            minimumFractionDigits: decimalPlaces ? 2 : 0,
            maximumFractionDigits: decimalPlaces ? 2 : 0
        }));
}