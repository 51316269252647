
import { IRestResponseWithData } from "models/rest/IRestResponseWithData";
import { IUserService } from "services/IUserService";
import Cookies from "universal-cookie";
import { fetchPostAsync } from "utilities/RestUtility";
import { isNullOrWhiteSpace } from "utilities/StringUtility";

export default class ProductionUserService implements IUserService {
    public async loginAsync(emailAddress: string, password: string) {
        const result =
            await fetchPostAsync<IRestResponseWithData<string>>("/users/login", { emailAddress, password });

        if (!isNullOrWhiteSpace(result.data)) {
            const cookies = new Cookies();
            cookies.set('__MIDSLEADENGINEPORTALAUTHCONTEXT', result.data, { path: '/' });

            return true;
        }

        return false;
    }
}