import React, { useState } from 'react';
import ProductionUserService from 'services/production/ProductionUserService';

const App: React.FC = () => {
	const [emailAddress, setEmailAddress] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [errorMessage, setErrorMessage] = useState<string>("");

	return (
		<div className="App">

			<div className="container-fluid">

				<div className="row">
					<div className="col-sm-6 client-bg">
						{/* <img src="/login/images/midigitalsolutions.svg" alt="Mitsubishi Digital Solutions" /> */}
						<img src="/login/images/nav-logo-wht-01.svg" alt="Mitsubishi Digital Solutions" />
					</div>
					<div className="col-sm-6 client-login">
						<div className="box">
							<div className="box-body">
								<h2>Portal Login</h2>
								<br />
								{
									errorMessage &&
									<div className="alert alert-danger">{errorMessage}</div>
								}
								<div className="form-group">
									<label>Email Address</label>
									<input type="text" className="form-control" placeholder="Email Address" onChange={(event) => { setEmailAddress(event.currentTarget.value); }} />
								</div>
								<div className="form-group">
									<label>Password</label>
									<input type="password" className="form-control" placeholder="Password" onChange={(event) => { setPassword(event.currentTarget.value); }} />
								</div>
								<br />
								<div className="form-group">
									<button className="btn btn-danger" onClick={async () => {
										if (emailAddress !== "" && password !== "") {
											if (await new ProductionUserService().loginAsync(
												emailAddress,
												password)) {
												window.location.href = '/secure';
											}
											else {
												setErrorMessage("Invalid login");
											}
										}
										else {
											setErrorMessage("Both email address and password are required");
										}
									}}>Submit</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default App;
